.smart-center-content-wrapper {
  padding-top: 26px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 26px;
  gap: 20px;

  @media (max-width: 1248px) {
    flex-direction: column;
  }
}

.detail-spinner {
  width: 100%;
  text-align: center;
  margin-block: 20px;
}

.smart-orders-filter-wrapper {
  width: 100%;
  height: fit-content;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(100, 100, 100, 0.15);
  padding: 20px 16px 27px 16px;

  @media (max-width: 1248px) {
    max-width: 100%;
  }
}

.table-chart-spinner {
  height: 3rem;
  width: 3rem;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 1rem;
}

.filter-item {
  max-width: 400px;
  min-width: 220px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.filter-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(68, 79, 89, 0.6);
}

.input-wrapper {
  position: relative;

  .search-icon {
    position: absolute;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(68, 79, 89, 0.6);
  }

  input {
    padding-left: 2rem;
  }
}

.filter-rating {
  min-width: 150px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.rating-buttons {
  display: flex;
  width: auto;
  gap: 0.5rem;
}

.rating-button {
  padding: 0.5rem 0.9rem;
  border-radius: 0.25rem;
  cursor: pointer;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  color: #495057;
  transition: all 0.2s ease-in-out;

  &.active {
    background-color: #007bff;
    border-color: #007bff;
    color: #ffffff;
  }

  &:hover {
    background-color: #e9ecef;
  }

  &.active:hover {
    background-color: #0056b3;
  }
}

.error_msg {
  position: absolute;
  top: 100%;
  font-size: 11px !important;
}

.relative {
  position: relative;
}

.dateinput_range {
  div {
    div {
      div {
        button {
          background-color: #f9f9f9 !important;
          border-color: #f9f9f9 !important;
          min-height: auto;
          padding: 0.5rem 0.9rem;
        }
      }
    }
  }
}
