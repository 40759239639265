.table-wrapper {
  width: 100%;
  background-color: white !important;
  // max-height: calc(100vh - 223px);
}

.custom-datatable {
  // background-color: var(--#{$prefix}primary) !important;
  font-family: Campton;
  box-shadow: none;
}

.edit-button-custom:hover svg path {
  fill: white; /* Change this to the desired color */
}

.row-edit-column {
  max-width: 100px;
}

.p-datatable-header {
  background-color: white;
  font-family: Campton;
  text-align: center !important;
}

.custom-column {
  height: 45px !important;
}

.custom-column-small {
  height: 15px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e5e7eb;
  border-width: 0 0 1px 0;
  padding: 0px 8px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.p-datatable .p-datatable-tbody > tr > td.custom-column-small {
  height: 15px !important;
}

.p-datatable .p-datatable-tbody > tr > td:not(.custom-column-small) {
  height: 45px !important;
}

.p-datatable-wrapper {
  background-color: white;
  max-height: unset;
  // min-height: 460px !important;
}

.custom-datatable .p-datatable-tbody {
  padding: 15px !important;
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: #f2f2f2;
  padding: 0px 8px !important;
}

.p-datatable .p-datatable-thead > tr > th.custom-column-small {
  height: 15px !important;
}
.p-datatable .p-datatable-thead > tr > th:not(.custom-column-small) {
  height: 15px !important;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: #f2f2f2;
  color: black;
}
.p-checkbox.p-highlight .p-checkbox-box {
  background-color: #64a0e3;
  border-color: #64a0e3;
}

.p-inputtext {
  font-size: 13px;
}

.p-dropdown-items {
  margin: 0;
  padding: 0;
}

.p-checkbox:hover {
  border-color: #64a0e3 !important;
}

.custom-datatable .p-datatable-tbody tr {
  border-bottom: 1px solid #dedede;
  text-align: center;
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: #f2f2f2;
  height: 52px !important;
}

/* Remove the border from the last table row */
.custom-datatable .p-datatable-tbody tr:last-child {
  border-bottom: none;
}

.status-table-cell {
  font-size: 11px;
}

.p-multiselect-items {
  padding: 0px;
}

.p-column-title {
  font-size: 14px;
  width: 100%;
}

.custom-column {
  font-size: 13px;
  min-height: 53px !important;
  // padding: 15px;
}

.p-multiselect-token {
  font-size: 13px;
}

.p-multiselect-label {
  display: flex;
  align-content: center;
}

.table-column-filter-icon {
  cursor: pointer;
  margin-left: 16px;
}

.table-column-filter-wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.table-column-filter-wrapper-footer {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  width: 100%;
  font-weight: 500;
}

.p-datatable .p-datatable-tfoot > tr > td {
  padding: 0px 8px !important;
}

.modal-title {
  width: 100%;
}

.p-selection-column[role="columnheader"] {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
}

// THUMBS

.table-thumb-divider {
  height: 1px;
  width: 100%;
  background-color: #dedede;
  margin-top: 15px;
  margin-bottom: 12px;
}

.thumbs-table-wrapper {
  display: flex;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  overflow-y: auto;
  position: relative;
  max-height: calc(100vh - 290px);
}

.thumbs-card {
  min-width: 200px;
  height: 310px;
  z-index: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  background-color: #ffff;
  box-shadow: 0px 0px 2px 0px rgba(100, 100, 100, 0.15);

  @media (min-width: 1616px) {
    width: calc((100% / 6) - 16.8px);
  }

  @media (max-width: 1616px) {
    width: calc((100% / 5) - 16px);
  }

  @media (max-width: 1372px) {
    width: calc((100% / 3) - 14px);
  }

  @media (max-width: 697px) {
    width: calc((100% / 2) - 11px);
  }

  @media (max-width: 477px) {
    width: 100%;
  }
}

.thumbs-card-hovered {
  min-width: 200px;
  height: 310px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  background-color: #ffff;
  box-shadow: 0px 0px 2px 0px rgba(100, 100, 100, 0.15);
  position: relative;

  @media (min-width: 1616px) {
    width: calc((100% / 6) - 16.8px);
  }

  @media (max-width: 1616px) {
    width: calc((100% / 5) - 16px);
  }

  @media (max-width: 1372px) {
    width: calc((100% / 3) - 14px);
  }

  @media (max-width: 697px) {
    width: calc((100% / 2) - 11px);
  }

  @media (max-width: 477px) {
    width: 100%;
  }
}

.thumbs-extra-card {
  padding: 10px;
  width: 100%;
  position: absolute;
  left: 0;
  height: 0px;
  top: 306px;
  z-index: 9999;
  border-radius: 4px;
  background-color: #ffff;
  box-shadow: 0px 1px 0px 0px rgba(100, 100, 100, 0.15);
  transition: height 0.2s linear;
  overflow: hidden;
}

.table-thumb-divider {
  height: 1px;
  width: 100%;
  background-color: #dedede;
  margin-top: 15px;
  margin-bottom: 12px;
}

.thumbs-card:hover .thumbs-extra-card,
.thumbs-card-hovered:hover .thumbs-extra-card {
  height: 100px; /* Replace 100px with the desired height */
  transition: height 0.2s linear;
}

.selected path {
  fill: #64a0e3 !important; /* Use the desired color */
  fill-opacity: 1;
  transition: 0.5 linear;
}

.table-column-sort-icon  {
  svg {
    path {
      fill: darkgrey;
    }
  }
}
