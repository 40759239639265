.placeholder-lg {
  width: 100%;
  height: 37px;
  background-color: rgba(68, 79, 89, 0.3);
}

.placeholder-md {
  width: 100%;
  height: 24px;
  background-color: rgba(68, 79, 89, 0.3);
}

.placeholder-sm {
  width: 100%;
  height: 17px;
  background-color: rgba(68, 79, 89, 0.3);
}

.placeholder-xs {
  width: 100%;
  height: 13px;
  background-color: rgba(68, 79, 89, 0.3);
}

.placeholder-color-light {
  background-color: rgba(242, 242, 242, 0.6) !important;
}

.placeholder-color-medium {
  opacity: 0.25;
  background-color: rgba(68, 79, 89, 0.60) !important;
}

.placeholder-color-dark {
  opacity: 0.5;
  background-color: #444F59 !important;
}

.placeholder-color-blue {
  opacity: 0.5;
  background-color: #64A0E3 !important;
}

.placeholder-color-green {
  opacity: 0.5;
  background-color: #50CFBD !important;
}

.placeholder-color-yellow {
  opacity: 0.5;
  background-color: #F7B84B !important;
}

.placeholder-color-red {
  opacity: 0.5;
  background-color: #F06548 !important;
}
