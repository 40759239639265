.icon-container {
  width: 100%;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-right: 50px;
  display: flex;
  justify-content: center;
}

.alert-margin {
  margin-top: 13px;
}

.avatar {
  width: 94px;
  height: 94px;
  margin-bottom: 15px;
}

.label-font {
  font-size: 14px;
}

.checkbox-gap {
  display: flex;
  gap: 11px;
}

.click-here {
  color: #64a0e3;
}

.authentication-header {
  margin-top: 150px;

  @media (max-width: 768px) {
    margin-top: 90px;
  }
}

.remember-me-label {
  font-size: 13px !important;
  margin-top: 2px !important;
}

.remember-me-checkbox {
  margin-top: 0 !important;
}
