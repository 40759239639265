.tabs-nav-custom-tabs {
  border: 0;
  margin-left: 20px;
  display: flex;
  flex-direction: row;

  > li {
    > a {
      color: #444F59;
    }
  }
  

  @media (max-width: 550px) {
    flex-direction: column;
    margin-left: 0px;
    gap: 8px;
    margin-bottom: 8px;
  
  }
}

.tabs-nav-item {
  width: auto;
  margin-right: 6px;
  justify-content: center;
  border-radius: 4px 4px 0px 0px;
  background-color: #f2f2f2;

  &:hover {
    background-color: white;
    transition: all linear 0.15s;

    a {
      color: #64A0E3 !important;
    }
  }

  .nav-link {
    width: 100%;
    display: flex;
    justify-content: center;
    border: 0;
    padding: 12px 40px;
    font-size: 14px;
  }
}
