.base-radio-default {
  width: 14px !important;
  height: 14px !important;
  margin-top: 0px !important;
  border-color: $brand-colour !important;

  &:hover {
    cursor: pointer;
  }
}

.form-check-input {
  &:checked {
    background-color: $brand-colour !important;
    border-color: $brand-colour !important;
  }

  &.black {
    border-color: #444F59 !important;

    &:checked {
      background-color: #444F59 !important;
      border-color: #444F59 !important;
    }
  }

  &.green {
    border-color: #50CFBD !important;

    &:checked {
      background-color: #50CFBD !important;
      border-color: #50CFBD !important;
    }
  }

  &.yellow {
    border-color: #F7B84B !important;

    &:checked {
      background-color: #F7B84B !important;
      border-color: #F7B84B !important;
    }
  }

  &.red {
    border-color: #F06548 !important;

    &:checked {
      background-color: #F06548 !important;
      border-color: #F06548 !important;
    }
  }
}