.Nofocus:focus {
  box-shadow: none !important;
}

.sweet-cross-alert-new::before {
  width: auto;
}

.sweer-cross-alert::before {
  width: auto;
}

.badge-alert{
  line-height: 1 !important;
}

.button-width {
  width: auto;
  min-width: 120px;
}

.email {
  font-weight: 700;
  color: #64a0e3;
}

.custom-footer {
  color: #64a0e3 !important;
  text-decoration: underline;
}

.swal2-footer {
  margin-top: 0 !important;
  padding: 1em 1em 1em !important;
  background-color: transparent;
}

.swal2-popup {
  padding: 0 !important;
}

.close-button {
  font-size: 18px;
  margin-top: 10px;
  margin-right: 10px;
  color: rgba(68, 79, 89, 0.3) !important;
}

.wrapper {
  width: 100px;
}

.badge {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 100%;
  margin-left: 72px;
  width: 21px;
  border: 2px solid #ffffff;
  height: 21px;
  z-index: 20;
}

.swal2-actions {
  margin: 0 !important;
  margin-bottom: 28px !important;
}

.swal2-confirm {
  background-color: none !important;
}

.sweet-font-opacity {

  color: rgba(68, 79, 89, 0.6);
}

.fontsize-title {
  font-size: 16px !important;
  font-weight: 500;
  font-family: Campton;
}

.fontsize-buttons {
  font-size: 13px !important;
  padding: 10px 20px;
  font-family: Campton;
  font-weight: 500;
}

.fontsize-footer {
  font-size: 11px !important;
}
