.text-input-style {
  border: 1.5px solid #DEDEDE;
  width: 100%;
  padding: 9px 15px;
  border-radius: 4px;
}

.form-control:disabled {
  background-color: #F2F2F2 !important;
  opacity: 1;
}

input:disabled {
  cursor: default;
  background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  color: rgba(68, 79, 89, 0.25) !important;
  border-color: #DEDEDE;
}

.input_subtitle_text {
  color:rgba(68, 79, 89, 0.60) !important;
}

.searchDisabled {
  border-color: #F2F2F2 !important;
  opacity: 1;
}

.text_input_icon {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 9px;
  right: 15px;
  z-index: 10;
  display: flex;
  color: rgba(68, 79, 89, 0.60) ;
  justify-content: center;
  align-items: center;
}

.text_input_read_only{
  background-color: #F2F2F2;
}

.custom-placeholder::placeholder {
  color: rgba(68, 79, 89, 0.60);
}

.input-plain-text {
  border: none;
}

.read-only-input {
  background-color: #F2F2F2 !important;
  border: 1px solid #DEDEDE !important;
  color: red;
}

.text_input_no_border {
  background-color: #F9F9F9 !important;
  border-color: #F9F9F9 !important;
}
