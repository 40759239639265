.modal-title {
  background-color: #566069;
  display: flex;
  align-items: center;
  padding: 12px 19px !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  color: white !important;
}

.modal-header{
  padding: 0px !important;
}

.modal-content {
  border: 0px !important;
  border-radius: 4px !important;
}


.moda-body.no-padding {
  padding: 0px !important;
  padding-bottom: 0px !important;
}

.moda-body:not(.no-padding) {
  padding: 20px !important;
  padding-bottom: 18px !important;
}