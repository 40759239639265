//
// _authentication.scss
//


.auth-page-wrapper{
  height: 280px;

  .auth-page-content{
    @media (max-width: 575.98px) {
      padding-bottom: 18px;
  }
      padding-bottom: $footer-height;
      position: relative;
      z-index: 2;
      width: 100%;
  }
  .footer{
      left: 0;
      background-color: transparent;
      color: var(--#{$prefix}body-color);
  }
}

.auth-one-bg-position{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 380px;
    
    @media (max-width: 575.98px) {
        height: 280px;
    }
}

.auth-one-bg{
    @media (max-width: 678px) {
      background-image: url("../../images/auth-one-bg-mobile.jpg");
      height: 306px;
      background-size: 100%;
      background-position: top;
      background-size: cover;
    }

    background-image: url("../../images/auth-one-bg.jpg");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    height: 521px;
     
    .bg-overlay{
        background: linear-gradient(to right, darken(#477EBC, 6%), $overlay);
        opacity: 0.8;
    }
}

.shape{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    >svg {
        width: 100%;
        height: auto;
        fill: var(--#{$prefix}body-bg);
    }
}

// auth-pass-inputgroup

.auth-pass-inputgroup{
    input[type="text"] + .btn .ri-eye-fill{
        &:before{
            content: "\ecb6";
        }
    }
}

.particles-js-canvas-el{
    position: relative;
}

// signin card title

.signin-other-title{
    position: relative;
    &:after{
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        border-top: 1px dashed var(--#{$prefix}border-color);
        top: 10px;
    }

    .title{
        display: inline-block;
        position: relative;
        z-index: 9;
        background-color: var(--#{$prefix}secondary-bg);
        padding: 2px 16px;
    }
}

// Auth cover

.auth-bg-cover{
    background: $auth-bg-cover;

    > .bg-overlay{
        background-image: url("../../images/cover-pattern.png");
        background-position: center;
        background-size: cover;
        opacity: 1;
        background-color: transparent;
    }

    .footer{
        color: rgba($white, .5);
    }
}

//passowrd validations 
#password-contain {
    display: none;

    p {
        padding-left: 13px;

        &.valid {
            color: $success;

            &::before {
                position: relative;
                left: -8px;
                content: "✔";
            }
        }

        &.invalid {
            color: $danger;

            &::before {
                position: relative;
                left: -8px;
                content: "✖";
            }
        }
    }
}

.login-title {
  @media (max-width: 678px) {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px; 
  }

  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}

.login-form-card {
  @media (max-width: 678px) {
    margin-top: 90px;
  }
}

.login-card-body {
  @media (max-width: 678px) {
    padding: 7px;
  }

  padding: 24px;
}

.login-mobile {
  @media (min-width: 679px) {
    display: none;
  }
}

.login-desktop {
  @media (max-width: 678px) {
    display: none;
  }
}

.login-terms-tag-mobile {
  @media (min-width: 400px) {
    display: none;
  }
}
 
.login-terms-tag-desktop {
  @media (max-width: 400px) {
    display: none;
  }
}