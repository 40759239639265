.mantine-PillsInput-input:focus-within {
  border-color: $primary-border-subtle !important;
}

.multiple-select-pill-with-x {
  background-color: $brand-colour !important;
  color: white !important;
  border-radius: 7px !important;

  .mantine-Pill-label {
    &::after {
      content: '';
      padding-right: 8px;
      border-right: 2px solid !important;
      border-color: rgba(249, 249, 249, 0.5)!important;
    }
  }

  .mantine-Pill-remove {
    padding-left: 4px;
    fill: white;
    stroke: white;
  }
}

.multiple-select-pill {
  background-color: $brand-colour !important;
  color: white !important;
  border-radius: 7px !important;

  .mantine-Pill-label {
    &::after {
      display: none;
    }
  }
}

.mantine-Combobox-option[aria-selected] {
  background-color: #E0ECF9 !important;
  color: $brand-colour !important;
}

.mantine-Combobox-option[data-combobox-selected] {
  background-color: #E0ECF9 !important;
  color: $brand-colour !important;
}

.mantine-PillsInput-input {
  align-content: center;
}
