.base-badge-default {
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  display: inline-block;

  &.base-badge-primary {
    color: white !important;

    &.new {
      background-color: #59CBEB !important;
    }

    &.manufacturing {
      background-color: #516E9B !important;
    }

    &.ready-to-process{
      background-color: #9E69AC !important;
    }

    &.ready-to-ship{
      background-color: #F7A745!important;
    }

    &.ready-to-pick-up{
      background-color: #F7C922 !important;
    }

    &.shiped{
      background-color: #46D9C1 !important;
    }

    &.delivered{
      background-color: #6EDB6E !important;
    }

    &.open-order{
      background-color: #7EB8F8 !important;
    }

    &.closed{
      background-color: #B7C1C9 !important;
    }

    &.canceled{
      background-color: #FF5656 !important;
    }

    &.waiting{
      background-color: #444F59 !important;
    }
  }
  
  &.base-badge-secondary {
    &.new {
      border: 1px solid #59CBEB !important;
      color: #59CBEB !important;
    }

    &.manufacturing {
      border: 1px solid #516E9B !important;
      color: #516E9B !important;
    }

    &.ready-to-process{
      border: 1px solid #9E69AC !important;
      color: #9E69AC !important;
    }

    &.ready-to-ship{
      border: 1px solid #F7A745!important;
      color: #F7A745!important;
    }

    &.ready-to-pick-up{
      border: 1px solid #F7C922 !important;
      color: #F7C922 !important;
    }

    &.shiped{
      border: 1px solid #46D9C1 !important;
      color: #46D9C1 !important;
    }

    &.delivered{
      border: 1px solid #6EDB6E !important;
      color: #6EDB6E !important;
    }

    &.open-order{
      border: 1px solid #7EB8F8 !important;
      color: #7EB8F8 !important;
    }

    &.closed{
      border: 1px solid #B7C1C9 !important;
      color: #B7C1C9 !important;
    }

    &.canceled{
      border: 1px solid #FF5656 !important;
      color: #FF5656!important;
    }

    &.waiting{
      border: 1px solid #444F59 !important;
      color: #444F59 !important;
    }
  }

  &.base-badge-soft {
    &.new {
      background-color: rgba(89, 203, 235, 0.3);
      color: #59CBEB !important;
    }

    &.manufacturing {
      background-color: rgba(81, 110, 155, 0.3);
      color: #516E9B !important;
    }

    &.ready-to-process{
      background-color: rgba(158, 105, 172, 0.3);
      color: #9E69AC !important;
    }

    &.ready-to-ship{
      background-color: rgba(247, 167, 69, 0.3);
      color: #F7A745 !important;
    }

    &.ready-to-pick-up{
      background-color: rgba(247, 201, 34, 0.3);
      color: #F7C922 !important;
    }

    &.shiped{
      background-color: rgba(70, 217, 193, 0.3);
      color: #46D9C1 !important;
    }

    &.delivered{
      background-color: rgba(110, 219, 110, 0.3);
      color: #6EDB6E !important;
    }

    &.open-order{
      background-color: rgba(126, 184, 248, 0.3);
      color: #7EB8F8 !important;
    }

    &.closed{
      background-color: rgba(183, 193, 201, 0.3);
      color: #B7C1C9 !important;
    }

    &.canceled{
      background-color: rgba(255, 86, 86, 0.3);
      color: #FF5656 !important;
    }

    &.waiting{
      background-color: rgba(68, 79, 89, 0.3);
      color: #444F59 !important;
    }
  }
}

.base-color-badge-wrapper {
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background-color: white; 
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #DEDEDE;
}

.base-color-badge-color {
  height: 10px;
  width: 10px;
  border-radius: 10px;
}
