.base-btn-default {
  box-shadow: $element-shadow;
  height: 36px;
  min-width: 94px;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $brand-colour;
  border-color: $brand-colour;

  &:active {
    color: white !important;
    border-color: #4776AB;
    background-color: #4776AB !important;
  }

  &:focus-visible {
    border-color: $brand-colour;
    background-color: $brand-colour;
    box-shadow: 0 0 0 0.25rem rgba(138, 183, 233, 0.4) !important;
  }

  &:hover {
    background-color: #5D94D1;
    border-color: #5D94D1;
  }

  &:disabled {
    border-color: #F2F2F2 !important;
    background-color: #F2F2F2 !important;
    color: rgba(68, 79, 89, 0.25) !important;

    &.base-btn-icon {
      & div svg path {
        fill: rgba(68, 79, 89, 0.25) !important;
      }
    }
  }

  &.btn-h-small {
    height: 30px;
  }
  
  &.btn-w-small {
    min-width: 94px;
  }

  &.btn-w-medium {
    min-width: 110px;
  }

  &.btn-w-large {
    min-width: 140px;
  }

  &.base-btn-icon {
    & div svg path {
      fill: white !important;
    }
  }

  &.grey {
    background-color: #F2F2F2;
    border: #F2F2F2;
    color: rgba(68, 79, 89, 0.6) !important;

    &:active {
      border-color: #CFCDCD !important;
      background-color: #CFCDCD !important;
    }
  
    &:focus-visible {
      border-color: #F2F2F2;
      background-color: #F2F2F2;
      box-shadow: 0 0 0 0.25rem rgba(228, 228, 228, 1) !important;
    }
  
    &:hover {
      background-color: #E4E4E4;
      border-color: #E4E4E4;
    }
  }

  &.red {
    background-color: #F06548;
    border-color: #F06548;
    color: white;

    &:active {
      border-color: #D85B40 !important;
      background-color: #D85B40 !important;
    }
  
    &:focus-visible {
      border-color: #F06548;
      background-color: #F06548;
      box-shadow: 0 0 0 0.25rem rgba(240, 101, 72, 0.4) !important;
    }
  
    &:hover {
      background-color: #E65F43;
      border-color: #E65F43;
    }
  }
}

.base-btn-secondary {
  box-shadow: $element-shadow;
  height: 36px;
  min-width: 94px;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent !important;
  border-color: $brand-colour;
  color: $brand-colour;
  transition: fill 0.1s linear, stroke 0.1s linear;

  &.base-btn-icon {
    & div svg path {
      transition: fill 0.1s linear, stroke 0.1s linear;
      fill: $brand-colour;
    }
  }

  &:active {
    color: #4776AB !important;
    border-color: #4776AB !important;

    &.base-btn-icon {
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: #4776AB !important;
      }
    }
  }

  &:focus-visible {
    border-color: $brand-colour;
    background-color: $brand-colour;
    box-shadow: 0 0 0 0.25rem rgba(138, 183, 233, 0.4) !important;
    color: $brand-colour;

    &.base-btn-icon {
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: $brand-colour !important;
      }
    }
  }

  &:hover {
    background-color: #5D94D1;
    border-color: #5D94D1;
    color: #5D94D1;

    &.base-btn-icon {
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: #5D94D1;
      }
    }
  }

  &:disabled {
    border-color: rgba(68, 79, 89, 0.25) !important;
    background-color: transparent !important;
    color: rgba(68, 79, 89, 0.25) !important;

    &.base-btn-icon {
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: rgba(68, 79, 89, 0.25) !important;
      }
    }
  }

  &.btn-h-small {
    height: 30px;
  }

  &.btn-w-small {
    min-width: 94px;
  }

  &.btn-w-medium {
    min-width: 110px;
  }

  &.btn-w-large {
    min-width: 140px;
  }
 
  &.grey {
    background-color: transparent;
    border-color: rgba(68, 79, 89, 0.30);
    color: rgba(68, 79, 89, 0.6);

    &.base-btn-icon {
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: rgba(68, 79, 89, 0.6);
      }
    }

    &:active {
      border-color: #444F59 !important;
      background-color: transparent !important;
      color: #444F59 !important;

      &.base-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: #444F59 !important;
        }
      }
    }
  
    &:focus-visible {
      border-color: #CFCDCD;
      background-color: transparent;
      box-shadow: 0 0 0 0.25rem rgba(228, 228, 228, 1) !important;
    }
  
    &:hover {
      background-color: transparent;
      border-color: rgba(68, 79, 89, 0.60);
      color: rgba(68, 79, 89, 0.60);

      &.base-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: rgba(68, 79, 89, 0.60);
        }
      }
    }

    &.base-btn-icon {
      & div svg path {
        fill: rgba(68, 79, 89, 0.6) !important;
      }
    }
  }

  &.red {
    background-color: transparent;
    border-color: #F06548;
    color: #F06548 !important;

    &:active {
      border-color: #D85B40 !important;
      background-color: transparent !important;
    }
  
    &:focus-visible {
      border-color: #F06548;
      background-color: #F06548;
      box-shadow: 0 0 0 0.25rem rgba(240, 101, 72, 0.4) !important;
    }
  
    &:hover {
      background-color: transparent;
      border-color: #E65F43;
    }

    &.base-btn-icon {
      & div svg path {
        fill: #F06548 !important;
      }
    }
  }
}

.base-btn-secondary-black {
  box-shadow: $element-shadow;
  height: 36px;
  min-width: 94px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent !important;
  border-color: $brand-colour;
  color: #444F59;
  transition: fill 0.0s linear, stroke 0.0s linear;

  &.base-btn-icon {
    & div svg path {
      transition: fill 0.0s linear, stroke 0.0s linear;
      fill: #444F59 !important;
    }
  }

  &:active {
    color: white !important;
    border-color: #5D94D1 !important;
    background-color: #5D94D1 !important;

    &.base-btn-icon {
      & div svg path {
        transition: fill 0.0s linear, stroke 0.0s linear;
        fill: white !important;
      }
    }
  }

  &:focus-visible {
    border-color: $brand-colour;
    background-color: $brand-colour;
    box-shadow: 0 0 0 0.25rem rgba(138, 183, 233, 0.4) !important;
    color: $brand-colour;

    &.base-btn-icon {
      & div svg path {
        transition: fill 0.0s linear, stroke 0.0s linear;
        fill: $brand-colour !important;
      }
    }
  }

  &:hover {
    background-color: #5D94D1 !important;
    border-color: #5D94D1;
    color: white;

    &.base-btn-icon {
      & div svg path {
        transition: fill 0.0s linear, stroke 0.0s linear;
        fill: white !important;
      }
    }
  }

  &:disabled {
    border-color: rgba(68, 79, 89, 0.25) !important;
    background-color: transparent !important;
    color: rgba(68, 79, 89, 0.25) !important;

    &.base-btn-icon {
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: rgba(68, 79, 89, 0.25) !important;
      }
    }
  }

  &.btn-h-small {
    height: 30px;
  }

  &.btn-w-small {
    min-width: 94px;
  }

  &.btn-w-medium {
    min-width: 110px;
  }

  &.btn-w-large {
    min-width: 140px;
  }
 
  &.grey {
    background-color: transparent;
    border-color: rgba(68, 79, 89, 0.30);
    color: rgba(68, 79, 89, 0.6);

    &.base-btn-icon {
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: rgba(68, 79, 89, 0.6);
      }
    }

    &:active {
      border-color: #444F59 !important;
      background-color: transparent !important;
      color: #444F59 !important;

      &.base-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: #444F59 !important;
        }
      }
    }
  
    &:focus-visible {
      border-color: #CFCDCD;
      background-color: transparent;
      box-shadow: 0 0 0 0.25rem rgba(228, 228, 228, 1) !important;
    }
  
    &:hover {
      background-color: transparent;
      border-color: rgba(68, 79, 89, 0.60);
      color: rgba(68, 79, 89, 0.60);

      &.base-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: rgba(68, 79, 89, 0.60);
        }
      }
    }

    &.base-btn-icon {
      & div svg path {
        fill: rgba(68, 79, 89, 0.6) !important;
      }
    }
  }

  &.red {
    background-color: transparent;
    border-color: #F06548;
    color: #F06548 !important;

    &:active {
      border-color: #D85B40 !important;
      background-color: transparent !important;
    }
  
    &:focus-visible {
      border-color: #F06548;
      background-color: #F06548;
      box-shadow: 0 0 0 0.25rem rgba(240, 101, 72, 0.4) !important;
    }
  
    &:hover {
      background-color: transparent;
      border-color: #E65F43;
    }

    &.base-btn-icon {
      & div svg path {
        fill: #F06548 !important;
      }
    }
  }
}

.icon-btn-default {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $brand-colour;
  border-color: $brand-colour;
  padding: 8px;

  &.round {
    border-radius: 50% !important;
  }

  &.icon-btn-icon {
    & div svg path {
      transition: fill 0.1s linear, stroke 0.1s linear;
      fill: white;
    }
  }

  &:active {
    border-color: #5D94D1 !important;
    background-color: #5D94D1 !important;
  }

  &:focus-visible {
    border-color: #5D94D1;
    background-color: #5D94D1;
    box-shadow: 0 0 0 0.25rem rgba(138, 183, 233, 0.4) !important;
  }

  &:hover {
    background-color: #5D94D1;
    border-color: #5D94D1;
  }

  &.green {
    background-color: #50CFBD;
    border-color: #50CFBD;

    &:active {
      border-color: #29BAA6 !important;
      background-color: #29BAA6 !important;
    }
  
    &:focus-visible {
      border-color: #29BAA6;
      background-color: #29BAA6;
      box-shadow: 0 0 0 0.25rem rgba(80, 207, 189, 0.4) !important;
    }
  
    &:hover {
      background-color: #29BAA6;
      border-color: #29BAA6;
    }
  }

  &.light-grey {
    background-color: #F3F6F9;
    border-color: #F3F6F9;

    &:active {
      border-color: #F3F6F9 !important;
      background-color: #F3F6F9 !important;
    }
  
    &:focus-visible {
      border-color: #F3F6F9;
      background-color: #F3F6F9;
      box-shadow: 0 0 0 0.25rem rgba(80, 207, 189, 0.4) !important;
    }
  
    &:hover {
      background-color: #F3F6F9;
      border-color: #F3F6F9;
    }
  }

  &.yellow  {
    background-color: #F7B84B;
    border-color: #F7B84B;

    &:active {
      border-color: #EEB046 !important;
      background-color: #EEB046 !important;
    }
  
    &:focus-visible {
      border-color: #EEB046;
      background-color: #EEB046;
      box-shadow: 0 0 0 0.25rem rgba(238, 176, 70, 0.4) !important;
    }
  
    &:hover {
      background-color: #EEB046;
      border-color: #EEB046;
    }
  }

  &.red  {
    background-color: #F06548;
    border-color: #F06548;

    &:active {
      border-color: #E65F43 !important;
      background-color: #E65F43 !important;
    }
  
    &:focus-visible {
      border-color: #F06548;
      background-color: #F06548;
      box-shadow: 0 0 0 0.25rem rgba(240, 101, 72, 0.4) !important;
    }
  
    &:hover {
      background-color: #E65F43;
      border-color: #E65F43;
    }
  }
}

.icon-btn-small {
  height: 30px !important;
  width: 30px !important; 
}

.icon-btn-secondary {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent;
  border-color: $brand-colour;
  padding: 8px;

  &.round {
    border-radius: 50% !important;
  }

  &.icon-btn-icon {
    & div svg path {
      transition: fill 0.1s linear, stroke 0.1s linear;
      fill: $brand-colour;
    }
  }

  &:active {
    border-color: #5D94D1 !important;
    background-color: #5D94D1 !important;

    & div svg path {
      transition: fill 0.1s linear, stroke 0.1s linear;
      fill: white;
    }
  }

  &:focus-visible {
    border-color: #5D94D1;
    background-color: #5D94D1;
    box-shadow: 0 0 0 0.25rem rgba(138, 183, 233, 0.4) !important;

    & div svg path {
      transition: fill 0.1s linear, stroke 0.1s linear;
      fill: white;
    }
  }

  &:hover {
    background-color: #5D94D1;
    border-color: #5D94D1;

    & div svg path {
      transition: fill 0.1s linear, stroke 0.1s linear;
      fill: white;
    }
  }

  &:disabled {
    background-color: transparent;
    border-color: rgba(68, 79, 89, 0.25) !important;

    &.icon-btn-icon {
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: rgba(68, 79, 89, 0.25) !important;
      }
    }
  }

  &.green {
    background-color: transparent;
    border-color: #50CFBD;

    &.icon-btn-icon {
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: #50CFBD;
      }
    }

    &:active {
      border-color: #29BAA6 !important;
      background-color: #29BAA6 !important;

      &.icon-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: white;
        }
      }
    }
  
    &:focus-visible {
      border-color: #29BAA6;
      background-color: #29BAA6;
      box-shadow: 0 0 0 0.25rem rgba(80, 207, 189, 0.4) !important;

      &.icon-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: white;
        }
      }
    }
  
    &:hover {
      background-color: #29BAA6;
      border-color: #29BAA6;

      &.icon-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: white;
        }
      }
    }
  }

  &.yellow {
    background-color: transparent;
    border-color: #F7B84B;

    &.icon-btn-icon {
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: #F7B84B;
      }
    }

    &:active {
      border-color: #EEB046 !important;
      background-color: #EEB046 !important;

      &.icon-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: white;
        }
      }
    }
  
    &:focus-visible {
      border-color: #EEB046;
      background-color: #EEB046;
      box-shadow: 0 0 0 0.25rem rgba(238, 176, 70, 0.4) !important;

      &.icon-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: white;
        }
      }
    }
  
    &:hover {
      background-color: #EEB046;
      border-color: #EEB046;

      &.icon-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: white;
        }
      }
    }
  }

  &.red {
    background-color: transparent;
    border-color: #F06548;

    &.icon-btn-icon {
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: #F06548;
      }
    }

    &:active {
      border-color: #E65F43 !important;
      background-color: #E65F43 !important;

      &.icon-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: white;
        }
      }
    }
  
    &:focus-visible {
      border-color: #F06548;
      background-color: #F06548;
      box-shadow: 0 0 0 0.25rem rgba(240, 101, 72, 0.4) !important;

      &.icon-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: white;
        }
      }
    }
  
    &:hover {
      background-color: #E65F43;
      border-color: #E65F43;

      &.icon-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: white;
        }
      }
    }
  }

  &.grey {
    background-color: transparent;
    border-color: rgba(68, 79, 89, 0.3);

    &.icon-btn-icon {
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: rgba(68, 79, 89, 0.3);
      }
    }

    &:active {
      background-color: rgba(68, 79, 89, 0.3) !important;
      border-color: transparent !important;

      &.icon-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: white;
        }
      }
    }
  
    &:focus-visible {
      background-color: rgba(68, 79, 89, 0.3);
      box-shadow: 0 0 0 0.25rem rgba(68, 79, 89, 0.4) !important;
      border-color: transparent !important;

      &.icon-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: white;
        }
      }
    }
  
    &:hover {
      background-color: rgba(68, 79, 89, 0.3) !important;
      border-color: transparent !important;

      &.icon-btn-icon {
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: white;
        }
      }
    }
  }
}

.icon-btn-noBorder {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent !important;
  padding: 8px;
  border: none;

  &.icon-btn-icon {
    & div svg path {
      transition: fill 0.1s linear, stroke 0.1s linear;
      fill: rgba(68, 79, 89, 0.3);
    }
  }

  &:active {
    &.icon-btn-icon { 
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: #444F59 !important;
      }
    }
  }
  
  &:focus-visible {
    box-shadow: 0 0 0 0.25rem rgba(68, 79, 89, 0.3) !important;

    &.icon-btn-icon { 
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: #444F59;
      }
    }
  }

  &:hover {
    &.icon-btn-icon { 
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: #444F59;
      }
    }
  }

  &.black {
    &.icon-btn-icon {
      & div svg path {
        transition: fill 0.1s linear, stroke 0.1s linear;
        fill: rgba(249, 249, 249, 0.3) !important;
      }
    }
  
    &:active {
      &.icon-btn-icon { 
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: #F9F9F9 !important;
        }
      }
    }
    
    &:focus-visible {
      box-shadow: 0 0 0 0.25rem rgba(68, 79, 89, 0.3) !important;
  
      &.icon-btn-icon { 
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: #F9F9F9;
        }
      }
    }
  
    &:hover {
      &.icon-btn-icon { 
        & div svg path {
          transition: fill 0.1s linear, stroke 0.1s linear;
          fill: #F9F9F9 !important;
        }
      }
    }
  }
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--vz-tooltip-arrow-height) calc(var(--vz-tooltip-arrow-width)* .5) 0;
  border-top-color: #444F59 !important;
  margin-top: -10px !important;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--vz-tooltip-arrow-width)* .5) var(--vz-tooltip-arrow-height);
  border-bottom-color: #444F59 ;
  margin-bottom: -10px !important;
}

.base-button-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  padding: 0px 6px;
  border-radius: 4px;
  font-size: 9.75px;
  font-weight: 600;
  margin-left: 8px;
  color: white !important;
}

.base-btn-alert {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  border: 2px solid white;
  background-color: #F06548;
  position: absolute;
  top: -5px;
  right: -5px;
}

.base-btn-alert-counter {
  min-width: 23px;
  min-height: 16px;
  padding: 2px 4px;
  background-color: #50CFBD;
  border-radius: 23px;
  position: absolute;
  top: -12px;
  right: -15px;
}

.bell-icon {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $brand-colour;
  border-color: $brand-colour;
  padding: 8px;
  border-radius: 50% !important;
  border: none;

  background-color: #F3F6F9;
  border-color: #F3F6F9;

  &:active {
    border-color: #E4E4E4 !important;
    background-color: #E4E4E4 !important;
  }

  &:focus-visible {
    border-color: #E4E4E4;
    background-color: #E4E4E4;
    box-shadow: 0 0 0 0.25rem rgba(80, 207, 189, 0.4) !important;
  }

  &:hover {
    background-color: #E4E4E4;
    border-color: #E4E4E4;
  }
}

.burguer-icon {
  position: relative;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $brand-colour;
  border-color: $brand-colour;
  padding: 8px;
  border-radius: 50% !important;
  border: none;

  background-color: #F3F6F9;
  border-color: #F3F6F9;

  &:active {
    border-color: #E4E4E4 !important;
    background-color: #E4E4E4 !important;
  }

  &:focus-visible {
    border-color: #E4E4E4;
    background-color: #E4E4E4;
    box-shadow: 0 0 0 0.25rem rgba(80, 207, 189, 0.4) !important;
  }

  &:hover {
    background-color: #E4E4E4;
    border-color: #E4E4E4;
  }
}

.green-noti {
  position: absolute;
  height: 8px;
  width: 8px;
  background-color: #50CFBD;
  top: -5px;
  right: -5px;
  border-radius: 50%;
}