#chartjs-tooltip {
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  pointer-events: none;
  opacity: 0;
  transition: all 0.1s ease;
  width: 340px;
  overflow: hidden;
  box-shadow: 5px 5px 18px rgb(0 0 0 / 20%);

  .tooltip-header {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    color: white;
    background: #1d447f;
    padding: 10px;
    p {
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
    }

    h3 {
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
    }
  }

  .tooltip-body {
    p {
      margin-bottom: 0;
      padding-block: 6px;
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      color: #566069;
    }
  }

  .tooltip-footer {
    margin-top: 10px;
    p {
      margin-bottom: 0;
      color: #1d447f;
      font-weight: bold;
    }
  }

  .client-info {
    h3 {
      margin: 0;
      color: white;
    }

    p {
      margin: 5px 0;
      font-weight: bold;

      span {
        font-weight: normal;
      }
    }
  }

  .order-info {
    p {
      margin: 5px 0;
    }
  }
}
