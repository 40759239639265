@font-face {
  font-family: "Campton";
  src: local("Campton light"),
    url("../../fonts/CamptonLight.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Campton";
  src: local("Campton regular"),
    url("../../fonts/CamptonBook.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Campton";
  src: local("Campton medium"),
    url("../../fonts/camptonmedium-webfont.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Campton";
  src: local("Campton semibold"),
    url("../../fonts/CamptonSemiBold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Campton";
  src: local("Campton bold"),
    url("../../fonts/CamptonBold.otf") format("opentype");
  font-weight: 700;
}
